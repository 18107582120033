/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mq from '../utils/media-query';
import Section from '../components/section';
import { windowGlobal } from '../utils';

const ContentHolder = styled.div`
  padding-top: 60%;
  background: url(${props => props.servicesBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  position: relative;
  .inner-content-holder {
    overflow: visible;
  }
  ${mq.m} {
    /* padding-top: 40%; */
    background-image: url(${props => props.servicesBgMd});
    .inner-content-holder {
      overflow: visible;
    }
  }
  ${mq.md} {
    .inner-content-holder {
      transform: translateY(-20%);
      margin-bottom: -13%;
    }
  }
  ${mq.l} {
    padding-top: 54rem;
    /* padding-top: 60%; */
    /* padding-top: 37rem; */
  }
  ${mq.xl} {
    /* padding-top: 50%; */
    .inner-content-holder {
      transform: translateY(-30%);
      margin-bottom: -17%;
    }
  }
  ${mq.xxl} {
    .inner-content-holder {
      transform: translateY(-14.5rem);
    }
  }
`;

const Services = styled.div`
  display: block;
  width: 100%;
  z-index: 10;
  position: relative;
  .services-content {
    padding: 15% 0 0 0;
    h2 {
      font-weight: bold;
      text-align: left;
    }
  }
  .tablet-holder {
    position: relative;
    width: 80vw;
    padding-top: 1rem;
    left: calc(50% - 30vw);
    img {
      margin: 0;
    }
  }
  .icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin: 0;
    padding: 0;
    .flex {
      flex-basis: 50%;
      flex-grow: 1;
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
    }
    .icon {
      width: 2.5rem;
      height: 2.5rem;
      margin-left: 0.5rem;
    }
    span {
      color: white;
      margin-left: 0.5rem;
      text-transform: uppercase;
      line-height: 1.2;
      font-size: 0.8rem;
    }
    small {
      text-transform: none;
    }
  }
  ${mq.m} {
    .content {
      padding-bottom: 0;
    }
    .services-content {
      /* padding: 2rem 2rem 60% 2rem; */
      padding-top: 0;
      margin-bottom: -5rem;
    }
    .tablet-holder {
      position: relative;
      bottom: 0;
      width: 50vw;
      left: calc(50% - 20vw);
    }
    .icons {
      position: relative;
      display: grid;
      top: 0;
      width: 100%;
      align-items: center;
      justify-content: stretch;
      grid-template-areas: 'area1 area2 area3 area4' 'area5 area6 area7 area8' 'area9 area10 area11 area12' 'area13 area14 area15 area16';
      grid-template-columns: 30% 20% 20% 30%;
      grid-template-rows: 30% 45% 45% 40%;
      span {
        line-height: 1;
        font-size: 1rem;
      }
      .icon {
        width: 3rem;
        height: 3rem;
      }
      .flex {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-of-type(1) {
          grid-area: area5;
          justify-self: flex-start;
        }
        &:nth-of-type(2) {
          grid-area: area9;
        }
        &:nth-of-type(3) {
          grid-area: area13;
          justify-self: flex-end;
        }
        &:nth-of-type(4) {
          grid-area: area6;
          grid-column-end: 4;
          justify-self: flex-start;
          align-self: flex-start;
        }
        &:nth-of-type(5) {
          grid-column-start: area2;
          grid-column-end: area3;
          grid-row-start: area2;
          grid-row-end: area3;
          justify-self: flex-end;
          align-self: flex-start;
        }
        &:nth-of-type(6) {
          grid-column-start: area7;
          grid-column-end: area8;
          grid-row: 2;
          align-self: flex-start;
        }
        &:nth-of-type(7) {
          grid-area: area4;
          justify-self: flex-end;
        }
        &:nth-of-type(8) {
          grid-area: area12;
          align-self: flex-start;
        }
        &:nth-of-type(9) {
          grid-area: area16;
          justify-self: flex-end;
        }
      }
    }
  }
  ${mq.md} {
    .icons {
      grid-template-columns: 30% 20% 20% 30%;
      grid-template-rows: 40% 65% 45% 40%;
    }
  }
  ${mq.xl} {
    .icons {
      grid-template-columns: 30% 20% 10% 35%;
      grid-template-rows: 50% 65% 45% 30%;
    }
    .tablet-holder {
      width: 50vw;
      left: calc(50% - 15rem);
      max-width: 40rem;
    }
  }
`;

const ServicesSection = ({
  servicesBg,
  servicesBgMd,
  id,
  sectionClassName,
  tablet,
  seguridadVideoVigilancia,
  accesoRemoto,
  controlVoz,
  controlEscenas,
  climatizacion,
  controlIluminacion,
  elementosDistancia,
  transmisionInformacion,
  sistemasDeAudio,
}) => (
  <Services>
    <ContentHolder servicesBg={servicesBg} servicesBgMd={servicesBgMd}>
      <div className="inner-content-holder">
        <Section id={id} className={sectionClassName}>
          <div className="services-content">
            <h2>
              Servicios
              <br />
              Inteligentes
            </h2>
          </div>
          <div className="icons">
            <div className="flex">
              <GatsbyImage image={seguridadVideoVigilancia} className="icon" />
              <span>
                Seguridad y
                <br />
                <strong>video vigilancia</strong>
              </span>
            </div>
            <div className="flex">
              <GatsbyImage image={accesoRemoto} className="icon" />
              <span>
                Acceso
                <br />
                <strong>remoto</strong>
              </span>
            </div>
            <div className="flex">
              <GatsbyImage image={controlVoz} className="icon" />
              <span>
                Control
                <br />
                <strong>por voz</strong>
              </span>
            </div>
            <div className="flex">
              <GatsbyImage image={controlEscenas} className="icon" />
              <span>
                Control de
                <br />
                <strong>escenas</strong>
              </span>
            </div>
            <div className="flex">
              <GatsbyImage image={climatizacion} className="icon" />
              <span>
                <strong>Climatización</strong>
              </span>
            </div>
            <div className="flex">
              <GatsbyImage image={controlIluminacion} className="icon" />
              <span>
                Control de
                <br />
                <strong>iluminación</strong>
                <br />
                <small>(On/Off - Atenuación)</small>
              </span>
            </div>
            <div className="flex">
              <GatsbyImage image={elementosDistancia} className="icon" />
              <span>
                Elementos
                <br />
                con mandos
                <br />
                <strong>a distancia</strong>
              </span>
            </div>
            <div className="flex">
              <GatsbyImage image={transmisionInformacion} className="icon" />
              <span>
                Transmisión
                <br />
                <strong>
                  información
                  <br />
                  del hogar
                </strong>
              </span>
            </div>
            <div className="flex">
              <GatsbyImage image={sistemasDeAudio} className="icon" />
              <span>
                Sistemas
                <br />
                <strong>de audio</strong>
              </span>
            </div>
          </div>
          <div className="tablet-holder">
            <GatsbyImage image={tablet} />
          </div>
        </Section>
      </div>
    </ContentHolder>
  </Services>
);

ServicesSection.propTypes = {
  servicesBg: PropTypes.string.isRequired,
  servicesBgMd: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  sectionClassName: PropTypes.string,
  tablet: PropTypes.any.isRequired,
  seguridadVideoVigilancia: PropTypes.any.isRequired,
  accesoRemoto: PropTypes.any.isRequired,
  controlVoz: PropTypes.any.isRequired,
  controlEscenas: PropTypes.any.isRequired,
  climatizacion: PropTypes.any.isRequired,
  controlIluminacion: PropTypes.any.isRequired,
  elementosDistancia: PropTypes.any.isRequired,
  transmisionInformacion: PropTypes.any.isRequired,
  sistemasDeAudio: PropTypes.any.isRequired,
};

ServicesSection.defaultProps = {
  sectionClassName: '',
};
export default ServicesSection;
