/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { GatsbyImage, getSrc } from "gatsby-plugin-image";
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mq from '../utils/media-query';
import Section from '../components/section';

const ContentHolder = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  .inner-content-holder {
    /* margin-top: -50%; */
  }
`;

const How = styled.div`
  display: block;
  width: 100%;
  background: white;
  z-index: 10;
  position: relative;
  .content {
    background: url(${props => props.background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 25% 100%;
    padding-bottom: 15rem;
    margin-bottom: -10rem;
  }
  .how-content {
    padding-top: 3rem;
    h2 {
      font-weight: bold;
      text-align: left;
      max-width: 35rem;
    }
    p {
      width: 100%;
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-around;
    margin-top: 5rem;
    .flex {
      display: flex;
      flex-direction: column;
      flex-basis: 30%;
      align-items: center;
      justify-content: center;
      .icon {
        width: 50vw;
        height: 50vw;
      }
      h3 {
        text-transform: uppercase;
        text-align: center;
        font-size: 2rem;
        margin-top: 1rem;
        color: ${props => props.theme.colors.primary};
      }
    }
  }
  ${mq.m} {
    .content {
      background-position: center 80%;
      padding-bottom: 11rem;
      margin-bottom: -5rem;
    }
    .how-content {
      p {
        max-width: 60%;
      }
    }
    .list {
      flex-direction: row;
      .flex {
        .icon {
          width: 12rem;
          height: 12rem;
        }
      }
    }
  }
  ${mq.md} {
    .content {
      background-position: center 80%;
      padding-bottom: 12rem;
      margin-bottom: 0rem;
    }
  }
  ${mq.l} {
    .content {
      background-position: center 80%;
      padding-bottom: 12rem;
    }
    .list {
      .flex {
        .icon {
          width: 15rem;
          height: 15rem;
        }
      }
    }
  }
`;

const HowSection = ({
  id,
  sectionClassName,
  seguridadCircle,
  confortCircle,
  ahorroCircle,
  howBg,
}) => (
  <How background={getSrc(howBg)}>
    <ContentHolder>
      <div className="inner-content-holder">
        <Section id={id} className={sectionClassName}>
          <div className="how-content">
            <h2>¿Cómo se vive en una casa inteligente?</h2>
            <p>
              Dexel es una línea de productos diseñados para la automatización de su hogar, con el
              cual podrá vivir la experiencia de tener una casa inteligente controlada desde la
              palma de su mano o por comandos de voz, difrutando de la seguridad y confort que tanto
              ha deseado.
            </p>
          </div>
          <div className="list">
            <div className="flex">
              <GatsbyImage image={seguridadCircle} className="icon" />
              <div>
                <h3>Seguridad</h3>
                <p>
                  Sin importar su locación usted podrá saber lo que ocurre en su hogar desde su
                  Smartphone pudiendo reaccionar frente a cualquier posibilidad de riesgo activando
                  cámaras, sirenas y sensores.
                </p>
              </div>
            </div>
            <div className="flex">
              <GatsbyImage image={confortCircle} className="icon" />
              <div>
                <h3>Confort</h3>
                <p>
                  El sistema Dexel le permitirá controlar sistemas de iluminación, audio y
                  electrodomésticos de su casa con tan solo un click o por medio de un comando por
                  voz.
                </p>
              </div>
            </div>
            <div className="flex">
              <GatsbyImage image={ahorroCircle} className="icon" />
              <div>
                <h3>Ahorro energético</h3>
                <p>
                  Conocer el consumo de energía eléctrica de cada artefacto que se encuentra
                  conectado al ecosistema smart es posible permitiéndole no solo controlarlo sino
                  disminuir su planilla.
                </p>
              </div>
            </div>
          </div>
        </Section>
      </div>
    </ContentHolder>
  </How>
);

HowSection.propTypes = {
  id: PropTypes.string.isRequired,
  sectionClassName: PropTypes.string,
  seguridadCircle: PropTypes.any.isRequired,
  confortCircle: PropTypes.any.isRequired,
  ahorroCircle: PropTypes.any.isRequired,
  howBg: PropTypes.any.isRequired,
};

HowSection.defaultProps = {
  sectionClassName: '',
};
export default HowSection;
