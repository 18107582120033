/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from "gatsby-plugin-image";
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Section from '../components/section';
import HomeSlider from '../components/home-slider';
import { mq } from '../utils';

const SocialIconsHolder = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 5%;
  right: 0;
  .icon-holder {
    background: white;
    margin: 0 0.25rem 0 0;
    .link {
      padding: 0.5rem;
      display: block;
      .icon {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }
  ${mq.xs} {
    flex-direction: column;
    .icon-holder {
      margin: 0.25rem 0 0 0;
      .link {
        .icon {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
  ${mq.m} {
    top: 65%;
    bottom: auto;
    .icon-holder {
      .link {
        .icon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
  ${mq.md} {
    top: 60%;
  }
  ${mq.l} {
    top: 56%;
    .icon-holder {
      margin: 0.5rem 0 0 0;
      .link {
        .icon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
`;

const HomeSection = ({ id, sectionClassName, slug, instagramIcon, facebookIcon }) => (
  <Section id={id} className={sectionClassName}>
    <HomeSlider slug={slug} />
    <SocialIconsHolder>
      <div className="instagram icon-holder">
        <a
          className="link"
          href="https://www.instagram.com/dexel.ec/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <GatsbyImage image={instagramIcon} className="icon" />
        </a>
      </div>
      <div className="facebook icon-holder red">
        <a
          className="link"
          href="https://www.facebook.com/Dexelec-226118841611758"
          target="_blank"
          rel="noreferrer noopener"
        >
          <GatsbyImage image={facebookIcon} className="icon" />
        </a>
      </div>
    </SocialIconsHolder>
  </Section>
);
HomeSection.propTypes = {
  id: PropTypes.string.isRequired,
  sectionClassName: PropTypes.string,
  slug: PropTypes.string.isRequired,
  instagramIcon: PropTypes.any.isRequired,
  facebookIcon: PropTypes.any.isRequired,
};

HomeSection.defaultProps = {
  sectionClassName: '',
};

export default HomeSection;
