import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import styled from '@emotion/styled';
import Downshift from 'downshift';
import { lighten } from 'polished';
import Section from '../components/section';
import MapMarker from '../components/map-marker';
import POSLocations from '../utils/POSLocations';

let L;
if (typeof window !== 'undefined') {
  L = require('leaflet') // eslint-disable-line
  delete L.Icon.Default.prototype._getIconUrl // eslint-disable-line
}
const MapHolder = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 30rem;
  position: relative;
  z-index: 10;
  .leaflet-container {
    width: 100%;
    height: 100%;
    .leaflet-marker-pane {
      .leaflet-marker-icon {
        width: auto !important;
        height: 2rem !important;
        mix-blend-mode: overlay;
        position: absolute;
      }
    }
  }
`;

const InputHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  > span {
    text-transform: uppercase;
    font-weight: 300;
  }
  > input {
    margin: 0.5rem 0 1rem;
    max-width: ${props => props.theme.maxInputWidth};
  }
  .downshift-dropdown {
    margin-top: -1rem;
    width: 100%;
    max-width: ${props => props.theme.maxInputWidth};
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
    position: absolute;
    top: 100%;
    z-index: 20;
    max-height: 20rem;
    overflow: auto;
    .dropdown-item {
      padding: 0.5rem 1rem;
      background-color: white;
      font-weight: 300;
      &.selected {
        background-color: ${props => lighten(0.1, props.theme.colors.primary)};
        color: white;
        &.selected-item {
          background-color: ${props => lighten(0.15, props.theme.colors.primary)};
        }
      }
      &.selected-item {
        color: white;
        background-color: ${props => props.theme.colors.primary};
        font-weight: 400;
      }
    }
  }
`;

const POSSection = ({ id, locationsEdges, sectionClassName }) => {
  const locations = locationsEdges ? locationsEdges.edges : [];
  const initialSelectedItem = {
    name: 'Ecuador',
    location: [-1.674347, -78.648294],
    capital: 'Quito',
  };
  const [zoom, setZoom] = useState(7);
  const [center, setCenter] = useState(initialSelectedItem.location);

  const onChange = selectedState => {
    if (selectedState) {
      setCenter(selectedState.location);
      if (zoom === 7) {
        setZoom(11);
      }
    }
  };

  if (typeof window !== 'undefined') {
    return (
      <Section id={id} className={sectionClassName} title="Puntos de venta">
        <Downshift
          onChange={selectedState => onChange(selectedState)}
          itemToString={data => (data ? data.name : '')}
          initialSelectedItem={initialSelectedItem}
        >
          {({
            getInputProps,
            getItemProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem,
            getLabelProps,
          }) => (
            <div>
              <InputHolder>
                <span {...getLabelProps()}>Ubicación</span>
                <input {...getInputProps({ placeholder: 'Selecciona una ubicación' })} />
                {isOpen ? (
                  <div className="downshift-dropdown">
                    {POSLocations.items
                      .filter(item => POSLocations.hasElement(item, inputValue))
                      .map((item, index) => (
                        <div
                          key={item.name}
                          className={`dropdown-item ${
                            highlightedIndex === index ? 'selected' : ''
                          } ${selectedItem === item ? 'selected-item' : ''}`}
                          {...getItemProps({ key: item.name, index, item })}
                        >
                          {item.name}
                        </div>
                      ))}
                  </div>
                ) : null}
              </InputHolder>
            </div>
          )}
        </Downshift>
        <MapHolder>
          <MapContainer center={center} zoom={zoom} scrollWheelZoom={false}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {locations.map(
              element => element.node.information.location && (
                <MapMarker
                  key={element.node.id}
                  position={element.node.information.location
                    .split(',')
                    .map(loc => parseFloat(loc))}
                  image={element.node.information.marker.localFile.publicURL}
                >
                  <Popup>
                    {element.node.content !== '' && (
                        <div dangerouslySetInnerHTML={{ __html: element.node.content }} /> // eslint-disable-line
                    )}
                    <div>
                        Ver en
                      {' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.google.com/maps/dir/?api=1&destination=${element.node.information.location}`}
                      >
                          Google Maps
                      </a>
                    </div>
                  </Popup>
                </MapMarker>
              )
            )}
          </MapContainer>
        </MapHolder>
      </Section>
    );
  }
  return null;
};

POSSection.propTypes = {
  sectionClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
  locationsEdges: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.shape({ node: PropTypes.shape({ id: PropTypes.string }) })),
  }),
};

POSSection.defaultProps = {
  sectionClassName: '',
  locationsEdges: [],
};
export default POSSection;
