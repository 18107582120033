/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import styled from '@emotion/styled';
import { FaChevronLeft } from 'react-icons/fa';
import { mq, normalizeProducts, extractCategories } from '../utils';
import ProductsGallery from './products-gallery';

const ProductsHolder = styled.div`
  width: 100%;
  .back-to-categories {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: underline;
    color: ${props => props.theme.colors.grey};
    &:focus,
    &:hover,
    &:active {
      color: ${props => props.theme.colors.primary};
    }
    svg {
      margin-right: 0.5rem;
    }
  }
  .categories-holder {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, 100%);
    .category {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      .image {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .title-holder {
        text-align: center;
        width: 100%;
        .title {
          color: ${props => props.theme.colors.darkGrey};
          font-size: 1.35rem;
          margin-top: 0.5rem;
          position: relative;
          width: 90%;
          display: inline-block;
          strong {
            font-weight: normal;
          }
          br {
            display: none;
          }
        }
        .gatsby-image-wrapper {
          display: none;
        }
        .description {
          display: none;
        }
      }
    }
  }
  ${mq.m} {
    .back-to-categories {
      margin: 0;
      width: 15rem;
    }
    .categories-holder {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(2, 50%);
      position: relative;
      .category {
        display: grid;
        grid-template-areas: 'image';
        justify-content: stretch;
        position: relative;
        .image {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .title-holder {
          text-align: left;
          width: 50%;
          position: absolute;
          left: 2rem;
          top: 37%;
          color: white;
          .title {
            font-size: 1.5rem;
            line-height: 1.2;
            position: relative;
            display: block;
            strong {
              font-weight: 900;
            }
            br {
              display: initial;
            }
            .gatsby-image-wrapper {
              display: block;
              width: 2.5rem;
              height: 2.5rem;
            }
          }
        }
      }
    }
  }
  ${mq.l} {
    .categories-holder {
      .category {
        .title-holder {
          top: 35%;
          .title {
            font-size: 2rem;
            &:after {
              content: '';
              display: block;
              height: 3px;
              width: 85%;
              margin: 0.25rem 0 0.5rem;
              background-color: ${props => props.theme.colors.primary};
            }
            .gatsby-image-wrapper {
              width: 2.5rem;
              height: 2.5rem;
            }
          }
          .description {
            display: block;
            width: 80%;
          }
        }
      }
    }
  }
`;

const SectionHolder = styled.div`
  position: relative;
  z-index: 10;
`;

const LIGHT_TYPE_FILTER = 'Tipo de luminaria';
const COLOR_FILTER = 'Color';
const LED_TYPE_FILTER = 'Tipo de led';
const BASE_FILTER = 'Base';
const POWER_FILTER = 'Potencia';
const COLOR_TEMP_FILTER = 'Temperatura de color';
const LUMENS_FILTER = 'Lúmenes';
const PROTECTION_LEVEL_FILTER = 'Grado de protección';
const PROFESSIONAL_FILTER = 'Profesional';

const defaultFilters = [
  LIGHT_TYPE_FILTER,
  COLOR_FILTER,
  LED_TYPE_FILTER,
  BASE_FILTER,
  POWER_FILTER,
  COLOR_TEMP_FILTER,
  LUMENS_FILTER,
  PROTECTION_LEVEL_FILTER,
  PROFESSIONAL_FILTER,
];

const ProductsDexel = ({ apiURL, location, wifiIconWhite }) => {
  const query = queryString.parse(location.hash);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [nextCategory, setNextCategory] = useState('');
  const queryEffect = () => {
    if (query.productos && query.productos !== '') {
      setSelectedCategory('');
      setNextCategory(query.productos);
    }
  };
  const categoryEffect = () => {
    if (nextCategory !== '') {
      setSelectedCategory(nextCategory);
      setNextCategory('');
    }
  };
  const backToCategories = event => {
    event.persist();
    event.preventDefault();
    if (event.type === 'click' || event.charCode === 32 || event.charCode === 13) {
      setSelectedCategory('');
    }
  };
  const showCategory = (event, slug) => {
    event.persist();
    event.preventDefault();
    if (event.type === 'click' || event.charCode === 32 || event.charCode === 13) {
      setSelectedCategory(slug);
    }
  };
  useEffect(queryEffect, [query.productos]);
  useEffect(categoryEffect, [nextCategory]);

  const titles = {
    'seguridad-y-video-vigilancia': {
      title: (
        <div>
          <strong>Seguridad </strong>
          <br />
& Video Vigilancia
        </div>
      ),
      description: 'Mantenga a su familia, hogar u oficina libre de peligro.',
    },
    'smart-kits': {
      title: (
        <div>
          <strong>Smart </strong>
          <br />
          Kits
        </div>
      ),
      description: 'Kits de domótica para automatizar su hogar por completo.',
    },
    'cerraduras-inteligentes': {
      title: (
        <div>
          <strong>Cerraduras </strong>
          <br />
          Inteligentes
        </div>
      ),
      description: 'Activadas con: Finger print, clave, tarjeta, o llave de emergencia.',
    },
    'plug-and-play': {
      title: (
        <div>
          <strong>Plug </strong>
          & Play
          <br />
          <GatsbyImage image={wifiIconWhite} />
        </div>
      ),
      description: 'Soluciones inteligentes para necesidades puntuales.',
    },
  };

  return (
    <StaticQuery
      query={graphql`query ProductsDexel {
  allWcProducts(
    sort: {fields: menu_order}
    filter: {status: {eq: "publish"}, categories: {elemMatch: {slug: {eq: "dexel"}}}}
  ) {
    totalCount
    edges {
      node {
        id
        name
        menu_order
        sku
        slug
        description
        short_description
        acf {
          videos
          pdf_button_name
          pdf_for_product_details
        }
        categories {
          id
          name
          slug
          menu_order
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  quality: 100
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        attributes {
          id
          name
          options
        }
        images {
          id
          name
          alt
          src
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 100
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
}
`}
      render={data => {
        const categories = extractCategories(data.allWcProducts);
        return (
          <SectionHolder id="productos" className="content">
            {categories.map(category => (
              <div key={`productos=${category.slug}`} id={`productos=${category.slug}`} />
            ))}
            <h2 className="section__title">Productos</h2>
            <ProductsHolder>
              {selectedCategory === '' && (
                <div className="categories-holder">
                  {categories.map(category => (
                    <div
                      key={category.id}
                      className="category"
                      role="button"
                      tabIndex={0}
                      onKeyPress={event => showCategory(event, category.slug)}
                      onClick={event => showCategory(event, category.slug)}
                    >
                      {category.image.localFile && (
                        <GatsbyImage
                          image={category.image.localFile.childImageSharp.gatsbyImageData}
                          className="image" />
                      )}
                      <div className="title-holder">
                        <span className="title">{titles[category.slug].title}</span>
                        <span className="description">{titles[category.slug].description}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {selectedCategory !== '' && (
                <>
                  <a
                    role="button"
                    tabIndex={0}
                    onClick={backToCategories}
                    onKeyPress={backToCategories}
                    className="back-to-categories"
                  >
                    <FaChevronLeft />
                    Volver a categorías
                  </a>
                  <ProductsGallery
                    apiURL={apiURL}
                    products={normalizeProducts(data.allWcProducts.edges)}
                    selectedCategory={selectedCategory}
                    categories={categories}
                    defaultFilters={defaultFilters}
                  />
                </>
              )}
            </ProductsHolder>
          </SectionHolder>
        );
      }}
    />
  );
};
ProductsDexel.propTypes = {
  apiURL: PropTypes.string.isRequired,
  location: PropTypes.shape({ hash: PropTypes.string, search: PropTypes.string }).isRequired,
  wifiIconWhite: PropTypes.any.isRequired,
};

export default ProductsDexel;
