/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SlickSlider from 'react-slick';
import styled from '@emotion/styled';
import { mq } from '../../utils';

const SliderStyles = styled.div`
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    &.slick-initialized {
      .slick-list {
        .slick-track {
          .slick-slide {
            display: block;
          }
        }
      }
    }
    &.slick-loading {
      .slick-list {
        .slick-track {
          visibility: hidden;
          .slick-slide {
            visibility: hidden;
          }
        }
      }
    }
    .slick-track,
    .slick-list {
      transform: translate3d(0, 0, 0);
    }
    .slick-arrow {
      display: none;
      color: ${props => props.theme.colors.primary};
      cursor: pointer;
      font-size: 2rem;
      height: 2rem;
      line-height: 0;
      position: absolute;
      top: 50%;
      width: 2rem;
      z-index: 20;
      &.slick-next {
        right: 0;
      }
      &.slick-prev {
        left: 0;
      }
      &:focus {
        outline: none;
        opacity: 1;
      }
      opacity: 0.7;
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0 auto;
      padding: 0;
      width: 100%;
      height: 100%;
      min-height: ${props => props.sliderHeight};
      &:focus {
        outline: none;
      }
      &.dragging {
        cursor: pointer;
        cursor: hand;
      }
      .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        &:before,
        &:after {
          display: table;
          content: '';
        }
        &:after {
          clear: both;
        }
        .slick-slide {
          display: none;
          float: left;

          height: 100%;
          min-height: 1px;
          img {
            display: block;
          }
          &.slick-loading {
            img {
              display: none;
            }
          }
          &.dragging {
            img {
              pointer-events: none;
            }
          }
          > div > div {
            outline: none;
          }
        }
      }
    }
    .slick-dots {
      position: absolute;
      top: 20%;
      right: 0;
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: auto;
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        position: relative;
        width: auto;
        cursor: pointer;
        &.slick-active {
          button {
            background: black;
          }
        }
        button {
          font-size: 0;
          width: 0.7rem;
          height: 0.7rem;
          margin: 0 1rem;
          padding: 0;
          cursor: pointer;
          background: white;
          border-radius: 50%;
          opacity: 1;
          &:hover,
          &:focus {
            outline: none;
            opacity: 1;
          }
        }
      }
    }
  }
  ${mq.m} {
    .slick-slider {
      .slick-arrow {
        display: block;
      }
      .slick-list {
        width: 100%;
      }
      .slick-track {
        height: 530px;
      }
      .slick-dots {
        top: 40%;
      }
    }
  }
  ${mq.l} {
    .slick-slider {
      .slick-list {
        .slick-track {
          height: 890px;
        }
      }
    }
  }
  ${mq.xl} {
    .slick-slider {
      .slick-list {
        .slick-track {
          height: 970px;
        }
      }
    }
  }
`;

// Hook
const useWindowSize = () => {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

const Slider = ({ children, arrows, dots, sliderSpeed, imageGuide }) => {
  const size = useWindowSize();
  let sliderHeight = 'auto';
  if (size && size.width) {
    if (size.width <= 1400) {
      sliderHeight = `${size.width
        / imageGuide.node.featured_media.localFile.childImageSharp.gatsbyImageData.aspectRatio}px`;
    }
  }
  return (
    <SliderStyles sliderHeight={sliderHeight}>
      <SlickSlider
        autoplay={sliderSpeed > 0}
        autoplaySpeed={sliderSpeed}
        arrows={arrows}
        dots={dots}
      >
        {children}
      </SlickSlider>
    </SliderStyles>
  );
};

Slider.propTypes = {
  arrows: PropTypes.bool,
  children: PropTypes.node.isRequired,
  dots: PropTypes.bool,
  sliderSpeed: PropTypes.number,
  imageGuide: PropTypes.any,
};

Slider.defaultProps = {
  arrows: true,
  dots: true,
  sliderSpeed: 0,
  imageGuide: {
    node: {
      featured_media: {
        localFile: {
          childImageSharp: {
            fluid: {
              aspectRatio: 1,
            },
          },
        },
      },
    },
  },
};

export default Slider;
