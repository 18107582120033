/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mq from '../utils/media-query';
import Section from '../components/section';

const ImageHolder = styled.div`
  width: 50%;
  position: absolute;
  right: 0;
  bottom: 10%;
  display: none;
  ${mq.m} {
    width: 50%;
    display: block;
  }
  ${mq.l} {
    bottom: 12vw;
  }
  ${mq.xl} {
    bottom: 15vw;
  }
`;

const AstronautHolder = styled.div`
  width: 30%;
  position: absolute;
  right: 0;
  top: 5%;
  display: block;
  z-index: -10;
  ${mq.m} {
    display: none;
  }
`;

const ContentHolder = styled.div`
  padding-top: 20%;
  background: url(${props => props.aboutBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
`;

const About = styled.div`
  display: block;
  width: 100%;
  margin-top: -20%;
  margin-bottom: -20%;
  .about-content {
    padding: 2rem 0 20% 0;
    h2 {
      font-weight: bold;
      text-align: left;
    }
    h3 {
      color: ${props => props.theme.colors.primary};
    }
    p {
      max-width: 70%;
    }
    .flex {
      display: flex;
    }
    .flex-col {
      flex-direction: column;
    }
    .icon {
      max-width: 5rem;
      width: 100%;
      height: 5rem;
    }
    .icon-list {
      width: 100%;
      justify-content: flex-start;
      align-items: baseline;
      text-transform: uppercase;
      .flex {
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        font-size: 0.7rem;
        span {
          margin-top: 0.5rem;
          text-align: center;
          max-width: 7rem;
          font-weight: 600;
        }
        &:last-child {
          span {
            max-width: 6rem;
          }
        }
      }
    }
    .works-with-holder {
      margin-top: 2rem;
      width: 100%;
      .works-with {
        background: white;
        border-radius: 5rem;
        padding: 1rem;
        display: flex;
        margin: 1rem auto 0;
        justify-content: space-between;
        max-width: 20rem;
        .pill {
          width: 10rem;
          max-width: 50%;
          height: 1.7rem;
          img {
            object-fit: contain !important;
          }
        }
      }
    }
  }
  ${mq.m} {
    .about-content {
      padding: 2rem 2rem 20% 2rem;
      p {
        max-width: 60%;
      }
      .icon-list,
      .works-with-holder {
        width: 50%;
      }
      .icon-list {
        .flex {
          font-size: 0.9rem;
        }
      }
      .works-with-holder {
        max-width: 22rem;
      }
    }
  }
  ${mq.l} {
    .about-content {
      padding: 2rem 4rem 20vw 4rem;
      p {
        max-width: 40%;
      }
      .icon-list {
        width: 40%;
      }
    }
  }
`;

const AboutSection = ({
  aboutBg,
  astronauta,
  astronautaSm,
  controlPorVoz,
  posibilidadDeIntegracion,
  facilUtilizacion,
  funcionaConAlexa,
  funcionaConGoogle,
  disponibleEnAppStore,
  disponibleEnGooglePlay,
  id,
  sectionClassName,
}) => (
  <About>
    <ContentHolder aboutBg={aboutBg}>
      <Section id={id} className={sectionClassName}>
        <div className="about-content">
          <h2>
            ¡Bienvenidos
            <br />
            al futuro!
          </h2>
          <p>
            Controlar su hogar será más sencillo desde hoy, todo en la palma de su mano desde
            cualquier parte del mundo.
          </p>
          <p>
            Dexel Smart Devices es una línea de productos desarrollados para brindar soluciones en
            el hogar, conectando al usuario con el mundo del internet de las cosas.
          </p>
          <h3>Nuestros campos de acción:</h3>
          <div className="flex icon-list">
            <div className="flex flex-col">
              <GatsbyImage image={controlPorVoz} className="icon" />
              <span>Control por voz</span>
            </div>
            <div className="flex flex-col">
              <GatsbyImage image={posibilidadDeIntegracion} className="icon" />
              <span>Posibilidad de integración</span>
            </div>
            <div className="flex flex-col">
              <GatsbyImage image={facilUtilizacion} className="icon" />
              <span>Fácil utilización</span>
            </div>
          </div>
          <div className="works-with-holder">
            <div className="works-with">
              <GatsbyImage image={funcionaConAlexa} className="pill" />
              <GatsbyImage image={funcionaConGoogle} className="pill" />
            </div>
            <div className="works-with">
              <GatsbyImage image={disponibleEnAppStore} className="pill" />
              <GatsbyImage image={disponibleEnGooglePlay} className="pill" />
            </div>
          </div>
        </div>
        <AstronautHolder>
          <GatsbyImage image={astronautaSm} />
        </AstronautHolder>
        <ImageHolder>
          <GatsbyImage image={astronauta} />
        </ImageHolder>
      </Section>
    </ContentHolder>
  </About>
);

AboutSection.propTypes = {
  aboutBg: PropTypes.string.isRequired,
  astronauta: PropTypes.any.isRequired,
  astronautaSm: PropTypes.any.isRequired,
  controlPorVoz: PropTypes.any.isRequired,
  posibilidadDeIntegracion: PropTypes.any.isRequired,
  facilUtilizacion: PropTypes.any.isRequired,
  funcionaConAlexa: PropTypes.any.isRequired,
  funcionaConGoogle: PropTypes.any.isRequired,
  disponibleEnAppStore: PropTypes.any.isRequired,
  disponibleEnGooglePlay: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  sectionClassName: PropTypes.string,
};

AboutSection.defaultProps = {
  sectionClassName: '',
};
export default AboutSection;
