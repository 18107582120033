import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FaTimesCircle } from 'react-icons/fa';
import styled from '@emotion/styled';

const CloseIcon = styled.div`
  position: absolute;
  right: 0.75rem;
  top: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
`;

const Modal = ({ children, closeCallback, isModalOpen }) => (
  <ReactModal
    isOpen={isModalOpen}
    shouldCloseOnOverlayClick
    contentLabel="Modal"
    ariaHideApp={false}
    onRequestClose={closeCallback}
    style={{
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        padding: '2.5rem',
        position: 'relative',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        maxHeight: '100vh',
        maxWidth: '100vw',
      },
    }}
  >
    <CloseIcon>
      <FaTimesCircle onClick={closeCallback} />
    </CloseIcon>
    {children}
  </ReactModal>
);

Modal.propTypes = {
  children: PropTypes.node,
  closeCallback: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  children: {},
};

export default Modal;
