import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getSrc } from "gatsby-plugin-image";
import styled from '@emotion/styled';
import Slider from './slider';
import mq from '../utils/media-query';

const Slide = styled.div`
  display: block !important;
  /* max-width: 940px; */
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  .slide-holder {
    display: block;
    align-items: center;
    justify-content: center;
    max-height: 100vh;

    position: relative;
    display: grid;
    grid-template-areas: 'center';
    grid-template-columns: 1fr;
    .slide-image-holder {
      grid-area: center;
      display: block;
      width: 100%;
      height: 100%;
      max-width: ${props => props.theme.maxWidth};
      z-index: 10;
      padding: 0 0.01rem;
    }
    .slide-content {
      width: 100%;
      z-index: 20;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      grid-area: center;
      margin: 1rem;
      p {
        margin: 0;
        padding: 0;
        font-size: 1rem;
        font-weight: 300;
        text-align: inherit;
      }
      br {
        display: block;
      }
      .slider__title {
        display: block;
        color: white;
        font-size: 1.5rem;
        line-height: 1;
        .bold {
          font-weight: bold;
        }
        .primary {
          color: ${props => props.theme.colors.primary};
        }
        .inline-icon {
          display: inline-block;
        }
        .wifi {
          background: url(${props => props.wifiIcon});
          background-repeat: no-repeat;
          background-size: cover;
          height: 2.5rem;
          width: 2.5rem;
          vertical-align: middle;
          margin-left: 0.5rem;
        }
      }
      .slider__content {
        display: none;
        font-weight: bold;
        color: white;
        max-width: 250px;
      }
      .products-1 {
        background: url(${props => props.products1});
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        width: 45%;
        height: 50%;
      }
      .products-2 {
        background: url(${props => props.products2});
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        width: 40%;
        height: 60%;
      }
      .products-3 {
        background: url(${props => props.products3});
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        width: 60%;
        height: 80%;
      }
      .products-4 {
        background: url(${props => props.products4});
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        width: 60%;
        height: 70%;
      }
    }
    ${mq.m} {
      .slide-content {
        margin-left: 10%;
        .slider__title {
          font-size: 3rem;
          .wifi {
            height: 4rem;
            width: 4rem;
            margin-left: 1rem;
          }
        }
        .slider__content {
          margin-top: 1rem;
          max-width: 300px;
          font-size: 1.6rem;
          display: block;
        }
        .products-1,
        .products-2,
        .products-3,
        .products-4 {
          display: block;
        }
        .products-1 {
          width: 45%;
          height: 50%;
        }
        .products-2 {
          width: 40%;
          height: 60%;
        }
        .products-3 {
          width: 50%;
          height: 60%;
        }
        .products-4 {
          width: 50%;
          height: 60%;
        }
      }
    }
    ${mq.l} {
      .slide-content {
        .slider__title {
          font-size: 4rem;
          .wifi {
            height: 5rem;
            width: 5rem;
            margin-left: 1rem;
          }
        }
        .slider__content {
          max-width: 600px;
        }
        .products-1 {
          width: 40%;
          height: 60%;
        }
        .products-2 {
          width: 50%;
          height: 70%;
        }
        .products-3 {
          width: 55%;
          height: 90%;
        }
        .products-4 {
          width: 50%;
          height: 80%;
        }
      }
    }
  }
`;

const HomeSlider = ({ slug }) => {
  // eslint-disable-next-line
  const onlyCurrentSlug = edge => edge.node.gm_slide_categories.every(el => el.slug === slug) && edge;
  return (
    <StaticQuery
      query={graphql`{
  wordpressWpGmBrands(slug: {eq: "dexel"}) {
    slug
    information {
      slider_speed
    }
  }
  wifiIcon: file(relativePath: {eq: "iconos/wifi.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 200
        quality: 100
        placeholder: BLURRED
        layout: CONSTRAINED
      )
    }
  }
  products1: file(relativePath: {eq: "slide_1_products.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 706
        quality: 100
        placeholder: BLURRED
        layout: CONSTRAINED
      )
    }
  }
  products2: file(relativePath: {eq: "slide_2_products.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 641
        quality: 100
        placeholder: BLURRED
        layout: CONSTRAINED
      )
    }
  }
  products3: file(relativePath: {eq: "slide_3_products.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 801
        quality: 100
        placeholder: BLURRED
        layout: CONSTRAINED
      )
    }
  }
  products4: file(relativePath: {eq: "slide_4_products.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 730
        quality: 100
        placeholder: BLURRED
        layout: CONSTRAINED
      )
    }
  }
  allWordpressWpGmSlides(
    sort: {fields: menu_order, order: ASC}
    filter: {gm_slide_categories: {elemMatch: {slug: {eq: "dexel"}}}}
  ) {
    edges {
      node {
        id
        content
        menu_order
        gm_slide_categories {
          slug
        }
        featured_media {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`}
      render={data => (
        <Slider
          arrows={false}
          sliderSpeed={parseInt(data.wordpressWpGmBrands.information.slider_speed, 10)}
          imageGuide={data.allWordpressWpGmSlides.edges && data.allWordpressWpGmSlides.edges[0]}
        >
          {data.allWordpressWpGmSlides.edges.map(
            slide => onlyCurrentSlug(slide)
              && (slide.node && (
                <Slide
                  key={slide.node.id}
                  wifiIcon={getSrc(data.wifiIcon.childImageSharp.gatsbyImageData)}
                  products1={getSrc(data.products1.childImageSharp.gatsbyImageData)}
                  products2={getSrc(data.products2.childImageSharp.gatsbyImageData)}
                  products3={getSrc(data.products3.childImageSharp.gatsbyImageData)}
                  products4={getSrc(data.products4.childImageSharp.gatsbyImageData)}
                >
                  <div className="slide-holder text-center">
                    <div
                      className="slide-content"
                      dangerouslySetInnerHTML={{ __html: slide.node.content }} // eslint-disable-line
                    />
                    <div className="slide-image-holder">
                      <GatsbyImage
                        image={slide.node.featured_media.localFile.childImageSharp.gatsbyImageData}
                        className="slide-image" />
                    </div>
                  </div>
                </Slide>
              ))
          )}
        </Slider>
      )}
    />
  );
};

HomeSlider.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default HomeSlider;
