import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-leaflet';
import MarkerPNG from '../images/default-marker.png';

// eslint-disable-next-line
const L = {
  Icon: {
    Default: {
      mergeOptions: () => {},
    },
  },
};

const MapMarker = ({ children, image, position }) => {
  if (typeof window !== 'undefined') {
    const L = require('leaflet') // eslint-disable-line
    delete L.Icon.Default.prototype._getIconUrl // eslint-disable-line
    L.Icon.Default.mergeOptions({
      // iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      // iconUrl: require('leaflet/dist/images/marker-icon.png'),
      // shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
      iconRetinaUrl: image,
      iconUrl: image,
      shadowUrl:
        'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
    });
    return <Marker position={position}>{children}</Marker>;
  }
  return null;
};

MapMarker.propTypes = {
  children: PropTypes.node,
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
  image: PropTypes.string,
};

MapMarker.defaultProps = {
  children: <div />,
  image: MarkerPNG,
};

export default MapMarker;
