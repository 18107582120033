/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from '@emotion/styled';
import mq from '../utils/media-query';
import Section from '../components/section';

const ContactForm = styled.form`
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  .image-holder {
    display: none;
  }
  .form-holder {
    color: ${props => props.theme.colors.text};
    display: grid;
    grid-template-areas: 'name' 'last-name' 'email' 'reason' 'contact-message' 'send';
    margin: 0 auto;
    width: 16rem;
    .send-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    label,
    .form-element {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 1rem;
    }
    label,
    .title {
      text-transform: uppercase;
    }
    input {
      max-width: ${props => props.theme.maxInputWidth};
      justify-self: center;
      &:focus {
        box-shadow: 0 0 0 0.2rem ${props => props.theme.colors.primary};
        border-color: transparent;
        outline: 0;
      }
    }
    textarea {
      max-width: ${props => props.theme.maxInputWidth};
      justify-self: center;
      &:focus {
        box-shadow: 0 0 0 0.2rem ${props => props.theme.colors.primary};
        border-color: transparent;
        outline: 0;
      }
    }
    button {
      background: ${props => props.theme.colors.primary};
      border: 1px solid;
      color: ${props => (props.blackFont ? props.theme.colors.black : props.theme.colors.complementaryTextColor)};
      cursor: pointer;
      max-width: ${props => props.theme.maxInputWidth};
      justify-self: center;
      border-radius: 5px;
      &:focus {
        box-shadow: 0 0 0 0.2rem ${props => props.theme.colors.primary};
        outline: 0;
      }
    }
    .form-element {
      .group {
        width: 100%;
        max-width: 30rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.75rem;
        text-align: center;
        label {
          border: 1px solid ${props => props.theme.colors.altText};
          color: ${props => (props.blackFont ? props.theme.colors.black : props.theme.colors.altText)};
          cursor: pointer;
          background: ${props => props.theme.colors.white};
          margin-bottom: 1rem;
          padding: 0.2rem 0.6rem;
          border-radius: 5px;
          font-size: 0.8rem;
          width: 8rem;
          user-select: none;
          &:focus {
            box-shadow: 0 0 0 0.2rem ${props => props.theme.colors.primary};
            outline: 0;
          }
          &.selected {
            background: ${props => props.theme.colors.primary};
            color: ${props => (props.blackFont ? props.theme.colors.black : props.theme.colors.complementaryTextColor)};
          }
          input[type='radio'] {
            opacity: 0;
            height: 0;
            width: 0;
            display: none;
          }
        }
        ${mq.s} {
          flex-direction: row;
          label {
            margin-bottom: 0rem;
          }
        }
      }
    }
  }
  ${mq.s} {
    .form-holder {
      width: 26rem;
    }
  }
  ${mq.m} {
    .image-holder {
      display: block;
      .gatsby-image-wrapper {
        width: 40vw;
        height: 100vh;
        min-height: 10rem;
        img {
          object-position: bottom !important;
        }
      }
    }
  }
`;

const Message = styled.div`
  line-height: 2;
  text-align: center;
  color: red;
  &.success {
    color: green;
  }
`;

const getEmptyContact = brandSlug => ({
  contact_name: '',
  contact_last_name: '',
  contact_phone: '',
  contact_email: '',
  contact_reason: 'information',
  contact_message: '',
  contact_brand_slug: brandSlug,
});

const ContactSection = ({ apiURL, blackFont, brandSlug, id, sectionClassName, hasCity, hasPhone, contactBg }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [contactMessage, setContactMessage] = useState({});
  const [values, setValues] = useState(getEmptyContact(brandSlug));

  const onChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const onRadioKeyPress = event => {
    if (event.charCode === 32 || event.charCode === 13) {
      event.preventDefault();
      onChange('contact_reason', event.target.querySelector('input').value);
    }
  };

  const onSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    setContactMessage({});
    axios({
      method: 'post',
      url: `https://${apiURL}/wp-json/contact/v1/send`,
      data: values,
      headers: {
        'Content-Type': 'json',
      },
    })
      .then(response => {
        setIsLoading(false);
        console.log(response);
        if (response.data.status !== 200) {
          setContactMessage({
            msg: 'Existe un error al enviar el mensaje, por favor intente mas tarde.',
            type: 'error',
          });
        } else {
          setContactMessage({ msg: 'Muchas gracias por comunicarte con nostros.', type: 'success' });
          setValues(getEmptyContact());
        }
      })
      .catch(error => {
        console.log('ERROR');
        console.error(error);
        setContactMessage({ msg: 'Existe un error, por favor intente mas tarde.', type: 'error' });
        setIsLoading(false);
      });
  };

  return (
    <Section id={id} className={sectionClassName} title="Contáctenos">
      <ContactForm onSubmit={onSubmit} blackFont={blackFont}>
        <div className="form-holder">
          <label htmlFor="name-input">
            <span>Nombre</span>
            <input
              type="text"
              id="name-input"
              name="contact_name"
              value={values.contact_name}
              disabled={isLoading}
              required
              onChange={event => {
                event.preventDefault();
                onChange('contact_name', event.target.value);
              }}
            />
          </label>
          <label htmlFor="lastName-input">
            <span>Apellido</span>
            <input
              type="text"
              id="lastName-input"
              name="contact_last_name"
              value={values.contact_last_name}
              disabled={isLoading}
              required
              onChange={event => {
                event.preventDefault();
                onChange('contact_last_name', event.target.value);
              }}
            />
          </label>
          <label htmlFor="email-input">
            <span>Correo Electrónico</span>
            <input
              type="email"
              id="email-input"
              name="contact_email"
              value={values.contact_email}
              disabled={isLoading}
              required
              onChange={event => {
                event.preventDefault();
                onChange('contact_email', event.target.value);
              }}
            />
          </label>
          {hasCity && (
            <label htmlFor="city-input">
              <span>Ciudad</span>
              <input
                type="text"
                id="city-input"
                name="contact_city"
                value={values.contact_city}
                disabled={isLoading}
                required
                onChange={event => {
                  event.preventDefault();
                  onChange('contact_city', event.target.value);
                }}
              />
            </label>
          )}
          {hasPhone && (
            <label htmlFor="phone-input">
              <span>Teléfono Celular</span>
              <input
                type="phone"
                id="phone-input"
                name="contact_phone"
                value={values.contact_phone}
                disabled={isLoading}
                required
                onChange={event => {
                  event.preventDefault();
                  onChange('contact_phone', event.target.value);
                }}
              />
            </label>
          )}
          <div className="form-element" onChange={event => onChange('contact_reason', event.target.value)}>
            <span className="title">Motivo</span>
            <div className="group">
              <label
                htmlFor="information-input"
                tabIndex={0}
                onKeyPress={onRadioKeyPress}
                className={values.contact_reason === 'information' ? 'selected' : ''}
              >
                <input
                  type="radio"
                  id="information-input"
                  name="contact_reason"
                  disabled={isLoading}
                  value="information"
                  defaultChecked
                />
                Experto comercial
              </label>
              <label
                htmlFor="distribution-input"
                tabIndex={0}
                onKeyPress={onRadioKeyPress}
                className={values.contact_reason === 'distribution' ? 'selected' : ''}
              >
                <input
                  type="radio"
                  id="distribution-input"
                  name="contact_reason"
                  disabled={isLoading}
                  value="distribution"
                />
                Servicio al cliente
              </label>
              <label
                htmlFor="projects-input"
                tabIndex={0}
                onKeyPress={onRadioKeyPress}
                className={values.contact_reason === 'projects' ? 'selected' : ''}
              >
                <input type="radio" id="projects-input" name="contact_reason" disabled={isLoading} value="projects" />
                Proyectos de automatización
              </label>
            </div>
          </div>
          <label htmlFor="message-input">
            <span>Comentario</span>
            <textarea
              id="message-input"
              name="contact_message"
              value={values.contact_message}
              rows={8}
              required
              disabled={isLoading}
              onChange={event => {
                event.preventDefault();
                onChange('contact_message', event.target.value);
              }}
            />
          </label>
          <div className="send-holder">
            <button disabled={isLoading} type="submit">
              Enviar
            </button>
            {contactMessage && <Message className={contactMessage.type}>{contactMessage.msg}</Message>}
          </div>
        </div>
        <div className="image-holder">
          <GatsbyImage image={contactBg} />
        </div>
      </ContactForm>
    </Section>
  );
};

ContactSection.propTypes = {
  apiURL: PropTypes.string.isRequired,
  blackFont: PropTypes.bool,
  brandSlug: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  sectionClassName: PropTypes.string,
  hasCity: PropTypes.bool,
  hasPhone: PropTypes.bool,
  contactBg: PropTypes.any.isRequired,
};

ContactSection.defaultProps = {
  sectionClassName: '',
  blackFont: false,
  hasCity: true,
  hasPhone: false,
};

export default ContactSection;
