import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AboutSection from '../sections/about';
import HomeSection from '../sections/home';
import POSSection from '../sections/points-of-sale';
import ContactSection from '../sections/contact';
import Products from '../components/products';
import ServicesSection from '../sections/services';
import HowSection from '../sections/how';

const IndexPage = ({ location }) => (
  <StaticQuery
    query={graphql`query Dexel {
  logo: file(relativePath: {eq: "logo.png"}) {
    publicURL
  }
  aboutBg: file(relativePath: {eq: "about_bg.jpg"}) {
    publicURL
  }
  servicesBg: file(relativePath: {eq: "services_bg.png"}) {
    publicURL
  }
  servicesBgMd: file(relativePath: {eq: "services_bg_md.png"}) {
    publicURL
  }
  instagramIcon: file(relativePath: {eq: "iconos/instagram.png"}) {
    childImageSharp {
      gatsbyImageData(width: 200, quality: 100, layout: CONSTRAINED)
    }
  }
  facebookIcon: file(relativePath: {eq: "iconos/facebook.png"}) {
    childImageSharp {
      gatsbyImageData(width: 200, quality: 100, layout: CONSTRAINED)
    }
  }
  astronauta: file(relativePath: {eq: "astronauta.png"}) {
    childImageSharp {
      gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
    }
  }
  astronautaSm: file(relativePath: {eq: "astronauta_sm.png"}) {
    childImageSharp {
      gatsbyImageData(width: 380, quality: 100, layout: CONSTRAINED)
    }
  }
  wifiIconWhite: file(relativePath: {eq: "iconos/wifi_blanco.png"}) {
    childImageSharp {
      gatsbyImageData(width: 200, quality: 100, layout: CONSTRAINED)
    }
  }
  controlPorVoz: file(relativePath: {eq: "iconos/control-por-voz-icono.png"}) {
    childImageSharp {
      gatsbyImageData(width: 151, quality: 100, layout: CONSTRAINED)
    }
  }
  posibilidadDeIntegracion: file(
    relativePath: {eq: "iconos/posibilidad-de-integracion-icono.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 151, quality: 100, layout: CONSTRAINED)
    }
  }
  facilUtilizacion: file(relativePath: {eq: "iconos/facil-utilizacion-icono.png"}) {
    childImageSharp {
      gatsbyImageData(width: 151, quality: 100, layout: CONSTRAINED)
    }
  }
  funcionaConAlexa: file(relativePath: {eq: "funciona-con-amazon-alexa.png"}) {
    childImageSharp {
      gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
    }
  }
  funcionaConGoogle: file(relativePath: {eq: "funciona-con-google.png"}) {
    childImageSharp {
      gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
    }
  }
  disponibleEnAppStore: file(relativePath: {eq: "disponible-en-app-store.png"}) {
    childImageSharp {
      gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
    }
  }
  disponibleEnGooglePlay: file(
    relativePath: {eq: "disponible-en-google-play.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
    }
  }
  tablet: file(relativePath: {eq: "tablet.png"}) {
    childImageSharp {
      gatsbyImageData(width: 765, quality: 100, layout: CONSTRAINED)
    }
  }
  seguridadVideoVigilancia: file(
    relativePath: {eq: "iconos/video-seguridad-icono.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 151, quality: 100, layout: CONSTRAINED)
    }
  }
  accesoRemoto: file(relativePath: {eq: "iconos/acceso-remoto-icono.png"}) {
    childImageSharp {
      gatsbyImageData(width: 151, quality: 100, layout: CONSTRAINED)
    }
  }
  controlVoz: file(relativePath: {eq: "iconos/mic-icono.png"}) {
    childImageSharp {
      gatsbyImageData(width: 151, quality: 100, layout: CONSTRAINED)
    }
  }
  controlEscenas: file(relativePath: {eq: "iconos/control-escenas-icono.png"}) {
    childImageSharp {
      gatsbyImageData(width: 151, quality: 100, layout: CONSTRAINED)
    }
  }
  climatizacion: file(relativePath: {eq: "iconos/termometro-icono.png"}) {
    childImageSharp {
      gatsbyImageData(width: 151, quality: 100, layout: CONSTRAINED)
    }
  }
  controlIluminacion: file(relativePath: {eq: "iconos/luz-icono.png"}) {
    childImageSharp {
      gatsbyImageData(width: 151, quality: 100, layout: CONSTRAINED)
    }
  }
  elementosDistancia: file(relativePath: {eq: "iconos/distancia-icono.png"}) {
    childImageSharp {
      gatsbyImageData(width: 151, quality: 100, layout: CONSTRAINED)
    }
  }
  transmisionInformacion: file(relativePath: {eq: "iconos/transmision-icono.png"}) {
    childImageSharp {
      gatsbyImageData(width: 151, quality: 100, layout: CONSTRAINED)
    }
  }
  sistemasDeAudio: file(relativePath: {eq: "iconos/volumen-icono.png"}) {
    childImageSharp {
      gatsbyImageData(width: 151, quality: 100, layout: CONSTRAINED)
    }
  }
  seguridadCircle: file(relativePath: {eq: "seguridad.png"}) {
    childImageSharp {
      gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
    }
  }
  confortCircle: file(relativePath: {eq: "confort.png"}) {
    childImageSharp {
      gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
    }
  }
  ahorroCircle: file(relativePath: {eq: "ahorro.png"}) {
    childImageSharp {
      gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
    }
  }
  contactBg: file(relativePath: {eq: "contact_bg.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
    }
  }
  howBg: file(relativePath: {eq: "how_bg.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  site {
    siteMetadata {
      title
      description
      author
      apiURL
      publicURL
    }
  }
  wordpressWpGmBrands(slug: {eq: "dexel"}) {
    slug
    content
    featured_media {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
    information {
      primary_color
      background_color
      logo {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
      }
    }
  }
  allWordpressWpGmLocations(
    sort: {fields: wordpress_id, order: ASC}
    filter: {gm_location_categories: {elemMatch: {slug: {eq: "dexel"}}}}
  ) {
    edges {
      node {
        id
        content
        information {
          location
          marker {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
}
`}
    render={data => (
      <Layout
        siteTitle={data.site.siteMetadata.title}
        logo={data.wordpressWpGmBrands.information.logo}
        accentColor={data.wordpressWpGmBrands.information.primary_color}
        backgroundColor={data.wordpressWpGmBrands.information.background_color}
      >
        <SEO
          title="Bienvenido al futuro"
          description="Dexel Smart devices le brinda lo mejor en productos tecnológicos para domotizar su casa brindándole otro nivel de seguridad, confort y ahorro de energía."
          thumbnail={`${data.site.siteMetadata.publicURL}${data.wordpressWpGmBrands.information.logo.localFile.publicURL}`}
          keywords={[
            'Smart home',
            'Domótica',
            'Seguridad',
            'Cámaras',
            'Automatización',
            'Control por voz',
            'Google home',
            'Alexa',
          ]}
        />
        <HomeSection
          slug="dexel"
          id="home"
          sectionClassName="content"
          instagramIcon={data.instagramIcon.childImageSharp.gatsbyImageData}
          facebookIcon={data.facebookIcon.childImageSharp.gatsbyImageData}
          image={data.wordpressWpGmBrands.information.logo}
        />
        <AboutSection
          id="nosotros"
          sectionClassName="content"
          aboutBg={data.aboutBg.publicURL}
          astronauta={data.astronauta.childImageSharp.gatsbyImageData}
          astronautaSm={data.astronautaSm.childImageSharp.gatsbyImageData}
          controlPorVoz={data.controlPorVoz.childImageSharp.gatsbyImageData}
          posibilidadDeIntegracion={data.posibilidadDeIntegracion.childImageSharp.gatsbyImageData}
          facilUtilizacion={data.facilUtilizacion.childImageSharp.gatsbyImageData}
          funcionaConAlexa={data.funcionaConAlexa.childImageSharp.gatsbyImageData}
          funcionaConGoogle={data.funcionaConGoogle.childImageSharp.gatsbyImageData}
          disponibleEnAppStore={data.disponibleEnAppStore.childImageSharp.gatsbyImageData}
          disponibleEnGooglePlay={data.disponibleEnGooglePlay.childImageSharp.gatsbyImageData}
        />
        <ServicesSection
          id="servicios"
          sectionClassName="content"
          servicesBg={data.servicesBg.publicURL}
          servicesBgMd={data.servicesBgMd.publicURL}
          tablet={data.tablet.childImageSharp.gatsbyImageData}
          seguridadVideoVigilancia={data.seguridadVideoVigilancia.childImageSharp.gatsbyImageData}
          accesoRemoto={data.accesoRemoto.childImageSharp.gatsbyImageData}
          controlVoz={data.controlVoz.childImageSharp.gatsbyImageData}
          controlEscenas={data.controlEscenas.childImageSharp.gatsbyImageData}
          climatizacion={data.climatizacion.childImageSharp.gatsbyImageData}
          controlIluminacion={data.controlIluminacion.childImageSharp.gatsbyImageData}
          elementosDistancia={data.elementosDistancia.childImageSharp.gatsbyImageData}
          transmisionInformacion={data.transmisionInformacion.childImageSharp.gatsbyImageData}
          sistemasDeAudio={data.sistemasDeAudio.childImageSharp.gatsbyImageData}
        />
        <HowSection
          id="como"
          howBg={data.howBg.childImageSharp.gatsbyImageData}
          sectionClassName="content"
          seguridadCircle={data.seguridadCircle.childImageSharp.gatsbyImageData}
          confortCircle={data.confortCircle.childImageSharp.gatsbyImageData}
          ahorroCircle={data.ahorroCircle.childImageSharp.gatsbyImageData}
        />
        <Products
          location={location}
          apiURL={data.site.siteMetadata.apiURL}
          wifiIconWhite={data.wifiIconWhite.childImageSharp.gatsbyImageData}
        />
        <POSSection
          id="puntos_de_venta"
          sectionClassName="content"
          locationsEdges={data.allWordpressWpGmLocations}
        />
        <ContactSection
          brandSlug={data.wordpressWpGmBrands.slug}
          apiURL={data.site.siteMetadata.apiURL}
          id="contactenos"
          sectionClassName="content"
          hasPhone
          contactBg={data.contactBg.childImageSharp.gatsbyImageData}
        />
      </Layout>
    )}
  />
);

IndexPage.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};
export default IndexPage;
